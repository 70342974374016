jQuery(document).foundation();

/*
These functions make sure WordPress
and Foundation play nice together.
*/

jQuery(document).ready(function() {

	// Remove empty P tags created by WP inside of Accordion and Orbit
	jQuery('.accordion p:empty, .orbit p:empty').remove();

	// Adds Flex Video to YouTube and Vimeo Embeds
	jQuery('iframe[src*="youtube.com"], iframe[src*="vimeo.com"]').each(function() {
		if ( jQuery(this).innerWidth() / jQuery(this).innerHeight() > 1.5 ) {
		  jQuery(this).wrap("<div class='widescreen responsive-embed'/>");
		} else {
		  jQuery(this).wrap("<div class='responsive-embed'/>");
		}
	});
});


//----------vide safari hack - https://github.com/VodkaBears/Vide/issues/206

jQuery(document).ready(function() {
	//FORCE VIDEO PLAY - SAFARI 11 ISSUE
	jQuery(window).load(function() {
		jQuery('.cover-background').data('vide').getVideoObject().play();
	});
});


//----------clickable divs----------------

jQuery(".clickable").click(function(){
     window.location=jQuery(this).find("a").attr("href");
     return false;
});

jQuery(".clickable-blank").click(function(){
		 window.open(jQuery(this).find("a").attr("href"));
	   return false;
});



//----------owl carousels------------------


jQuery('#full-image-carousel-home').owlCarousel({
    items: 1,
    loop:true,
		dots: true,
    margin:0,
		mouseDrag: false,
		touchDrag: false,
		pullDrag: false,
		animateOut: 'fadeOut',
    animateIn: 'fadeIn',
		nav:false,
		autoplay:true,
		autoplayTimeout:3000,
		autoplayHoverPause:false
  });

jQuery('.full-image-carousel').on('initialized.owl.carousel changed.owl.carousel', function(e) {
    if (!e.namespace)  {
      return;
    }
    var carousel = e.relatedTarget;
    jQuery('.slider-counter').text(carousel.relative(carousel.current()) + 1 + '/' + carousel.items().length);
  }).owlCarousel({
    items: 1,
    loop:true,
		dots: true,
    margin:0,
		nav:false,
		autoplay:true,
		autoplayTimeout:5000,
		autoplayHoverPause:false
  });

jQuery('.4Items-carousel').owlCarousel({
	dots: false,
	nav:false,
	margin:20,
	loop:true,
	nav:true,
	navText : ["<i class='kikor kiko-triangular-arrow-left'></i>","<i class='kikor kiko-triangular-arrow-right'></i>"],
	responsive:{
        0:{
            items:2,
        },
        600:{
            items:2,
        },
        1000:{
            items:4,
        }
    }
});

jQuery('.logo-carousel').owlCarousel({
	items:4,
	dots: false,
	nav:false,
	margin:20,
	nav:true,
	navText : ["<i class='kikor kiko-triangular-arrow-left'></i>","<i class='kikor kiko-triangular-arrow-right'></i>"],
	responsive:{
				0:{
						items:2,
						nav:false,
						navText: false,
				},
				600:{
						items:4,
				},
				1000:{
						items:4,
						nav:true,
						navText : ["<i class='kikor kiko-triangular-arrow-left'></i>","<i class='kikor kiko-triangular-arrow-right'></i>"],
				}
		}
});

//smooth anchor scroller

jQuery(document).ready(function(){
	// applies to all links
	jQuery("a").on('click', function(event) {

		// checking if this has a value to avoid console error this time, ignore downloads tab hashes
		if (this.hash !== "" && (this.hash !== "#panel1") && (this.hash !== "#panel2") && (this.hash !== "#panel3") && (this.hash !== "#panel4") && (this.hash !== "#panel5") && (this.hash !== "#panel6") && (this.hash !== "#panel7") && (this.hash !== "#panel8"))  {
			// Prevent default anchor click behavior
			//event.preventDefault();
 
			// Store hash
			var hash = this.hash;

			// Using jQuery's animate() method to add smooth page scroll
			// The optional number (800) specifies the number of milliseconds it takes to scroll to the specified area
			jQuery('html, body').animate({
				scrollTop: jQuery(hash).offset().top
			}, 1200, function(){

				// Adding hash (#) to URL when done scrolling (default click behavior)
				window.location.hash = hash;
			});
		} // End if
	});
});
